(() => {
	const insertDateElement = document.querySelector('.introduction__headline');
	const dayNumber = new Date().getDay();
	const userLang = document.querySelector('html').getAttribute('lang');
	const weekDaysGerman = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'];
	const weekDaysEnglish = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

	const replacementMap = {
		insert_date: userLang === 'de' ? weekDaysGerman[dayNumber] : weekDaysEnglish[dayNumber]
	};

	if(insertDateElement){
		if(insertDateElement.innerText.includes('{{')){
			const splitted = insertDateElement.innerText.split(/({{ | }})/);

			Object.keys(replacementMap).forEach((replaceKey) => {
				const replacementIndex = splitted.findIndex((strg) => {
					return strg === replaceKey;
				});

				// Remove mustaches
				splitted.splice(replacementIndex + 1, 1);
				splitted.splice(replacementIndex - 1, 1);

				// Replace string, index is moved one place
				splitted[replacementIndex - 1] = replacementMap[replaceKey];

				insertDateElement.innerText = splitted.join('');
			});
		}
	}
})();



