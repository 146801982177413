(() => {
	const elementList = document.querySelectorAll('.navigation__list__element--withChilds');
	let delayTimeout = null;
	let mouseOutTimeout = null;

	const onMouseOver = (ul) => {
		return () => {
			delayTimeout = setTimeout(() => {
				// Hide all active elements
				elementList.forEach((elem) => {
					const ul = elem.querySelector('ul');
					if(!elem.classList.contains('navigation__list__element--active')){
						ul.style.height = '0px';
					}
				});

				const count = ul.querySelectorAll('li').length;

				ul.style.height = (count * 55) + 'px';
			}, 200)
		}
	};
	const onMouseOut = (ul) => {
		return () => {
			const isParentActive = ul.parentNode.classList.contains('navigation__list__element--active');

			if(!isParentActive){
				clearTimeout(delayTimeout);
				clearTimeout(mouseOutTimeout);

				mouseOutTimeout = setTimeout(() => {
					ul.style.height = '0px';
				}, 200);
			}
		}
	};

	elementList.forEach((elem) => {
		const ul = elem.querySelector('ul');

		if(elem.classList.contains('navigation__list__element--active')){
			onMouseOver(ul)();
		}
		else{
			onMouseOut(ul)();
		}

		elem.addEventListener('mouseover', onMouseOver(ul));
		elem.addEventListener('mouseout', onMouseOut(ul));
	});
})();
