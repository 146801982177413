(() => {
	const mobileNav = document.querySelector('.mainContainer__navigation--mobile');
	const mobileTrigger = mobileNav.querySelector('.mobileTrigger');
	const navigationTriggerClass = 'navigation--closed';

	const onClick = () => {
		const navigationElement = mobileNav.querySelector('.navigation');

		if(!navigationElement.classList.contains(navigationTriggerClass)){
			navigationElement.classList.add(navigationTriggerClass);
		}
		else{
			navigationElement.classList.remove(navigationTriggerClass);
		}
	};

	mobileTrigger.addEventListener('click', onClick);

	// Set navigation height
	const navElements = mobileTrigger.querySelectorAll('.navigation__list__element');
	setTimeout(() => {
		mobileTrigger.style.height = navElements.length * 35 + 40 + 'px' ;
	});
})();
