(() => {
	const moreButtonHeight = 20;

	const onClick = (columnElement, moreLinkElement) => {
		return (event) => {
			const mixedElement = columnElement.querySelector('.mixed');
			mixedElement.style.height = 'auto';

			moreLinkElement.style.display = 'none';
		};
	};

	const updateMoreLinks = () => {
		const mixedTextElementList = document.querySelectorAll('.mixed--withText');

		mixedTextElementList.forEach((textElement) => {
			const columnElement = textElement.closest('.grid__column');
			const previousElement = columnElement.previousElementSibling;
			const imageElement = previousElement.querySelector('.mixed__background');
			const textContentElement = textElement.querySelector('.mixed__description');
			const moreLinkElement = columnElement.querySelector('.mixed__moreLink');

			if (imageElement && textContentElement.clientHeight > imageElement.clientHeight) {
				textElement.style.height = imageElement.clientHeight - moreButtonHeight + 'px';
				if (moreLinkElement) {
					moreLinkElement.style.display = 'block';
				}

				moreLinkElement.addEventListener('click', onClick(columnElement, moreLinkElement));
			} else {
				if (moreLinkElement) {
					moreLinkElement.style.display = 'none';
				}
			}
		});
	};

	setTimeout(updateMoreLinks);

	window.onresize = updateMoreLinks;
})();
