(() => {
	const buttonTriggerList = document.querySelectorAll('.buttonExpandable__trigger');
	const triggerClassOpened = 'buttonExpandable__preview--opened';
	const triggerClassClosed = 'buttonExpandable__preview--closed';

	const onClick = (event) => {
		const parent = event.target.closest('.buttonExpandable');
		const previewElement = parent.querySelector('.buttonExpandable__preview');
		const previewIsAlreadyOpen = previewElement.classList.contains(triggerClassOpened);

		// Close all
		document.querySelectorAll('.' + triggerClassOpened).forEach((buttonTrigger) =>{
			buttonTrigger.style.height = '0px';
			buttonTrigger.classList.add(triggerClassClosed);

			setTimeout(() => {
				buttonTrigger.classList.remove(triggerClassOpened);
			}, 500);
		});

		if (!previewIsAlreadyOpen) {
			previewElement.classList.remove(triggerClassClosed);
			previewElement.style.height = previewElement.scrollHeight + 'px';
			previewElement.classList.add(triggerClassOpened);
		}
	};

	buttonTriggerList.forEach((buttonTrigger) => {
		buttonTrigger.addEventListener('click', onClick);
	});
})();
