const isTrackingAllowed = navigator.doNotTrack != 1 && navigator.doNotTrack != 'yes' && window.doNotTrack != 1 && navigator.msDoNotTrack != 1;

const loadGAonConsent = () => {
	window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
	ga('create', 'UA-157299525-1', 'auto');
	ga('set', 'anonymizeIp', true);
	ga('send', 'pageview');
	var gascript = document.createElement('script');
	gascript.async = true;
	gascript.src = 'https://www.google-analytics.com/analytics.js';
	document.getElementsByTagName('head')[0].appendChild(gascript, document.getElementsByTagName('head')[0]);
}

if (document.cookie.split(';').filter(function(item) {
	return item.indexOf('cookieconsent_status=allow') >= 0
}).length) {
	if (isTrackingAllowed) {
		loadGAonConsent();
	}
}

window.addEventListener('load', function(){
	const options = {
		'palette': {
			'popup': {
				'background': '#F0F0F0'
			},
			'button': {
				'background': 'transparent',
				'text': '#000000',
				'border': '#00adba'
			}
		},
		'theme': 'classic',
		'type': 'opt-in',
		'content': {
			'message': 'Unsere Webseite verwendet Google Analytics, um diese so interessant wie möglich zu gestalten.\n Diese Cookies dienen zur Verbesserung der Funktionalität unserer Webseite und deren Anpassung an das Nutzerverhalten.\n\nMit dem Klick auf den Button „akzeptieren“ oder mit Ihrer weiteren Nutzung der Webseite erklären Sie sich damit einverstanden. Weitere Informationen hierzu finden Sie in unserer Datenschutzerklärung.',
			'allow': 'Cookies erlauben',
			'deny': 'Ablehnen',
			'link': '(link öffnen)',
			'href': '/en/data-privacy'
		},
		onStatusChange: function(status, chosenBefore) {
			var type = this.options.type;
			var didConsent = this.hasConsented();
			if (type == 'opt-in' && didConsent) {
				// enable cookies
				loadGAonConsent();
			}
		}
	};

	if(navigator.language.includes('de')){
		options.content = {
			'message': 'Unsere Webseite verwendet Google Analytics, um diese so interessant wie möglich zu gestalten.\n Diese Cookies dienen zur Verbesserung der Funktionalität unserer Webseite und deren Anpassung an das Nutzerverhalten.\n\nMit dem Klick auf den Button „akzeptieren“ oder mit Ihrer weiteren Nutzung der Webseite erklären Sie sich damit einverstanden. Weitere Informationen hierzu finden Sie in unserer Datenschutzerklärung.',
			'allow': 'Cookies erlauben',
			'deny': 'Ablehnen',
			'link': '(link öffnen)',
			'href': '/de/data-privacy'
		}
	}

	if(isTrackingAllowed){
		window.cookieconsent.initialise(options)
	}

	const revokeElement = document.getElementById("revokeCookieConsent");
	if(revokeElement){
		revokeElement.onclick = function() {
			document.querySelector('.cc-revoke').click();
		}
	}
});
