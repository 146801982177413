(() => {
	const isScrollable = window.innerWidth > document.documentElement.clientWidth;

	if (isScrollable) {
		document.querySelector('body').classList.add('scrollable')
	}

	if(navigator.userAgent.match(/Trident.*rv:11\./)) {
		document.querySelector('body').classList.add('ie11');
	}
})();
