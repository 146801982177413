(() => {
	const disclaimerKey = 'disclaimer';
	const wasDisclaimerClosedBefore = !!sessionStorage.getItem(disclaimerKey);
	const disclaimerElement = document.querySelector('.disclaimer');
	const mainElement = document.querySelector('.mainContainer');

	if (!disclaimerElement) {
		return;
	}

	if (!wasDisclaimerClosedBefore) {
		disclaimerElement.style.display = 'flex';
		mainElement.style.display = 'none';
		const buttonTrigger = document.querySelector('.closeDisclaimerHandler');

		const onClick = () => {
			window.scrollTo(0, 0);
			disclaimerElement.style.display = 'none';
			mainElement.style.display = 'block';

			sessionStorage.setItem('disclaimer', true);
		};

		buttonTrigger.addEventListener('click', onClick);

		document.addEventListener('keyup', function (event) {
			if (event.defaultPrevented) {
				return;
			}

			const key = event.key || event.keyCode;

			if (key === 'Escape' || key === 'Esc' || key === 27) {
				onClick();
			}
		});
	}
})();

